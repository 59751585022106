<template>
	<div>
		<TableList :columns="columns" :get_table_list="this.get_table_list" :form_data_seo="form_data_seo"
			@changeStatus='changeStatus2' :slot_table_list="['operation','status']"
			:submit_preprocessing="submit_preprocessing" ref="list" :rowKey="'hotel_id'">
			<template slot="left_btn">
				<div class="left_btn_box">
					<a-button @click="change_Status({})" type="primary"> 批量补贴</a-button>
					<p>
						<a-icon type="info-circle" />点击批量补贴会将筛选条件下的所有人员的未补贴状态变更为已补贴
					</p>
				</div>
			</template>
			<template slot="right_btn">
				<a :href="'/manage/crm.export/subsidyExport' | export_form" target="_blank">
					<a-button class="a_btn">导出</a-button>
				</a>
			</template>
			<template slot="operation" slot-scope="data">
				<span>
					<a @click="see(data.record)">查看</a>
				</span>
				<a-divider type="vertical" />
				<a @click="change_Status(data.record)" v-if="data.record.status==0">补贴</a>

			</template>
			<template slot="status" slot-scope="data">
				<span>
					{{data.record.status==1?'已补贴':'未补贴'}}
				</span>

			</template>
		</TableList>
	</div>
</template>

<script>
	// @ is an alias to /src
	import EditPop from "@/components/EditPop";
	import Form from "@/components/Form";
	import TableList from "@/components/TableList";
	import SelectUser from "@/components/SelectUser";
	import TransferTree from "@/components/TransferTree";
	import {
		getSubsidyList,
		changeStatus
	} from "@/api/evection";
	import {
		getUserList
	} from "@/api/personnel";
	import {
		message
	} from 'ant-design-vue'
	const columns = [{
			title: "姓名",
			dataIndex: "username"
		},
		{
			title: "部门",
			dataIndex: "department"
		},
		{
			title: "统计时间",
			dataIndex: "statistical_time",

		},

		{
			title: "出差天数",
			dataIndex: "days",

		},
		{
			title: "补贴状态",
			dataIndex: "status",
			scopedSlots: {
				customRender: "status"
			}
		},
		{
			title: "应得补贴",
			dataIndex: "money",
			scopedSlots: {
				customRender: "money"
			}
		},

		{
			title: "补贴时间",
			dataIndex: "subsidy_time"
		},

		{
			title: "操作",
			dataIndex: "operation",
			scopedSlots: {
				customRender: "operation"
			}
		}
	];



	export default {
		name: "Index",
		components: {
			EditPop,
			Form,
			TableList,
			SelectUser,
		},
		data() {
			return {
				config: this.$config,
				get_table_list: getSubsidyList,
				submit_preprocessing: {
					array_to_string: ['department_id', 'place', 'role_id']
				},
				columns,
				change_data: {},
				form_data_seo: {
					list: [

						{
							type: "text",
							name: "keyword",
							title: "姓名",
							placeholder: "请输入姓名",
							options: {}
						},
						{
							type: "tree-select",
							name: "department_id",
							title: "部门",
							options: {},
							treeData: [],
							multiple: true
						},
						{
							type: "select",
							name: "role_id",
							title: "岗位职务",
							mode: "default",
							options: {},
							list: []
						},
						{
							type: 'select',
							title: '补贴状态',
							name: 'status',
							options: {},
							placeholder: "请选择",
							list: [{
									key: '0',
									value: "未补贴"
								},
								{
									key: '1',
									value: "已补贴"
								},

							]
						},
						{
							type: "month",
							name: "time",
							title: "统计时间",
							options: {},

						},

					],

					...this.$config.form_data_seo
				},
			};
		},


		async created() {
			this.$method.get_department().then(res => {
				this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'department_id',
					'treeData', res)
			});
			this.$method.get_role().then(res => {
				this.form_data_seo.list.forEach(item => {
					if (item.name == "role_id") {
						item.list = res;
					}
				});
			});
		},

		methods: {
			changeStatus2(data) {
				this.change_data = data
			},
			change_Status(data) {
				let obj = {}
				if (data.id) {
					obj.id = data.id
				} else {
					obj = {
						...this.change_data
					}
				}
				let flag = false
				for (let item in obj) {
					if (obj[item]) {
						flag = true
					}
				}
				if (!flag) {
					message.error('请先筛选查询后进行批量操作');
					return false;
				}
				changeStatus({
					data: obj,
					info: true
				}).then(res => {
					this.$refs.list.get_list();
				})
			},
			see(key) {
				this.$router.push("/evection/subsidy_details?id=" + key.id);
			},

		}
	};
</script>

<style lang="less">
	@import url("../../assets/less/app.less");

	.left_btn_box {
		flex: inherit;

		p {
			margin: 5px 0px;
		}
	}

	.edit_pop {
		.ant-col-19 {
			width: 77%;
		}

		.ant-form-item-label {
			width: 22%;
		}
	}

	.red_color {
		color: red;
	}

	.green_color {
		color: #00CC10;
	}

	.recharge_all {
		font-size: 16px;
		display: flex;
		color: #333;

		.all_money {
			font-size: 20px;
			color: #FF0000;
			margin-left: 10px;
			margin-right: 5px;
			display: inline-block;
		}

		p:nth-child(2),
		p:nth-child(3) {
			margin-left: 40px;
		}
	}
</style>
